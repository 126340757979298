import { combineReducers } from "@reduxjs/toolkit";
import giftCardSlice from "./slices/giftCardSlice";
import listAmountSlice from "./slices/listAmountSlice";
import orderDetailsSlice from "./slices/orderDetailsSlice";
import storePurchaseSlice from "./slices/storePurchaseSlice";
import formPersistSlice from "./slices/formPersistSlice";
import paymentStatusSlice from "./slices/paymentStatusSlice";
import cardApisSlice from "./slices/cardApisSlice";
import authTokenSlice from "./slices/authTokenSlice";

const rootReducer = combineReducers({
  giftCard: giftCardSlice,
  listAmount: listAmountSlice,
  storePurchase: storePurchaseSlice,
  orderDetails: orderDetailsSlice,
  formData: formPersistSlice,
  paymentStatus: paymentStatusSlice,
  cardCheckoutapi: cardApisSlice,
  token: authTokenSlice,
});

export default rootReducer;
