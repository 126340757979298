import React, { Suspense, lazy } from "react";
import "../styles/common.scss";

import { Routes, Route } from "react-router-dom";

import GiftCardsPage from "../pages/GiftCards";
import CheckoutPage from "../pages/Checkout";
import TransactionSuccessPage from "../pages/TransactionSuccess";
import TransactionFailedPage from "../pages/TransactionFailed";
import ScrollToTop from "./ScrollToTop";
import CommonLayout from "./Layouts/CommonLayout";

const App = () => {
  return (
    <Suspense fallback={null}>
      <ScrollToTop />
      <CommonLayout>
        <Routes>
          <Route path="/" element={<GiftCardsPage />} />
          <Route path="/:lang" element={<GiftCardsPage />} />
          <Route path="/checkout/:slug/:lang" element={<CheckoutPage />} />
          <Route
            path="/order-summary/:orderId/success/:langTrans"
            element={<TransactionSuccessPage />}
          />
          <Route
            path="/order-summary/:orderId/failed/:langTrans"
            element={<TransactionFailedPage />}
          />
        </Routes>
      </CommonLayout>
    </Suspense>
  );
};

export default App;
