import axios from "axios";
import { useState, useEffect } from "react";

let menu = {};
const useMenu = () => {
  const [header, setHeader] = useState({ header: null, style: null });
  const lang = localStorage.getItem("language_type");

  useEffect(() => {
    axios
      .get(
        `https://cloudlab.ikea.ae/afgikeapps/corsproxy/ownav.php?lang=${
          !lang ? "en" : lang
        }&country=om&fragment=style`
      )
      .then((resp) => {
        menu.style = resp?.data;
        setHeader({ ...menu });
      });

    axios
      .get(
        `https://cloudlab.ikea.ae/afgikeapps/corsproxy/ownav.php?lang=${
          !lang ? "en" : lang
        }&country=om&fragment=header`
      )
      .then((resp) => {
        menu.header = resp?.data;
        setHeader({ ...menu });
      });
  }, []);

  return header;
};

export default useMenu;
