import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCardCheckoutApi } from "../store/slices/cardApisSlice";
import { getCancelOrder } from "../store/slices/orderDetailsSlice";

export const useCardsCheckout = ({ id }) => {
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [cardholderName, setCardholderName] = useState("");
  const [loadingShimmer, setLoadingShimmer] = useState(false);
  const [errorMsgCard, setErrorMsgCard] = useState("");
  const [cardClick, setCardClick] = useState(false);
  const [gpayClick, setGpayClick] = useState(false);

  const { t } = useTranslation(["common"]);

  const dispatch = useDispatch();
  const { slug, lang: langSlug } = useParams();
  const navigate = useNavigate();

  let langString = langSlug;

  const cancelButton = async () => {
    const resultAction = await dispatch(getCancelOrder(slug));

    if (getCancelOrder.fulfilled.match(resultAction)) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  };

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  localStorage.setItem("id", id);

  const handleOnCardTokenized = useCallback(
    async (data) => {
      try {
        const resultAction = await dispatch(
          getCardCheckoutApi({
            token: data?.token,
            card_type: data?.card_type,
            issuer_country: data?.issuer_country,
            scheme: data?.scheme,
            card_no: data?.last4,
            order_id: localStorage.getItem("id"),
            app_token: localStorage.getItem("authToken"),
          })
        );

        let responseWith3D = resultAction?.payload?.data?.data?.status;
        let responseWithOut3D = resultAction?.payload?.data?.data?.approved;

        if (responseWith3D == "Pending") {
          setLoadingShimmer(false);
          window.location.href =
            resultAction?.payload?.data?.data?._links?.redirect?.href;
        } else if (responseWithOut3D == true) {
          setLoadingShimmer(false);
          navigate(`/order-summary/${id}/success/${langString}`);
        } else if (responseWithOut3D == false) {
          setLoadingShimmer(false);
          navigate(`/order-summary/${id}/failed/${langString}`);
        } else {
          setErrorMsgCard(t("Something_wrong"));
          setLoadingShimmer(false);
          setTimeout(() => setErrorMsgCard(""), 4000);
        }
      } catch (error) {
        // console.log("On Card Tokenized Callback handler Error: ", error);
      }
    },
    [id]
  );

  const onCardValidationChanged = useCallback(
    (valid) => setPayButtonDisabled(!valid),
    [setPayButtonDisabled]
  );

  const handleCardholderName = useCallback(
    (e) => setCardholderName(e.target.value),
    [setCardholderName]
  );
  return {
    handleCardholderName,
    onCardValidationChanged,
    handleOnCardTokenized,
    payButtonDisabled: payButtonDisabled || !cardholderName,
    cardholderName,
    cancelButton,
    loadingShimmer,
    setLoadingShimmer,
    errorMsgCard,
    cardClick,
    setCardClick,
    gpayClick,
    setGpayClick,
  };
};
