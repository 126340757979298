import Style from "./Transaction.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FailedSvg from "./transaction-failed.svg";
import { useDispatch } from "react-redux";
import { getCardStatus } from "../../store/slices/cardApisSlice";

const TransactionFailed = () => {
  const dispatch = useDispatch();

  const { langTrans: langSlug, orderId } = useParams();

  useEffect(() => {
    if (window?.location?.search) {
      dispatch(
        getCardStatus({
          status: "failed",
          order_id: orderId,
          token: localStorage.getItem("authToken"),
        })
      );
    }
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const handleHide = () => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      navigate("/ar");
      window.location.reload();
    } else {
      navigate("/en");
      window.location.reload();
    }
  };

  return (
    <div className={"container"}>
      <div className={Style.contentWrapper}>
        <div class={Style.fig_wrapper}>
          <figure class={Style.fig_img_wrapper}>
            <img src={FailedSvg} alt="Transaction Failed" />
          </figure>
        </div>
        <div className={Style.message_title}>{t("order_failed")}</div>
        <div className={`${Style.message_status} ${Style.error}`}>{t("Status_error_msg")}</div>
        <button onClick={handleHide} className={"custom_btn custom_btn-primary"}>
          {t("Home")}
        </button>
      </div>
    </div>
  );
};

export default TransactionFailed;
