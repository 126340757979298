import Style from "./CheckoutPayment.module.scss";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCardsCheckout } from "../../../logic/useCardsCheckout";
import { useNavigate, useParams } from "react-router-dom";
import GooglePayButton from "@google-pay/button-react";
import { useGpay } from "../../../logic/useGpay";
import GpaySvg from "./gpay.png";
import { CreditCard } from "react-feather";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";

const CheckoutPayment = ({ data }) => {
  const REACT_APP_CHECKOUT_PUBLIC_KEY = process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;

  const REACT_APP_GPAY_MERCHANT_ID = process.env.REACT_APP_GPAY_MERCHANT_ID;

  const REACT_APP_GPAY_MERCHANT_NAME = process.env.REACT_APP_GPAY_MERCHANT_NAME;

  const {
    handleCardholderName,
    onCardValidationChanged,
    handleOnCardTokenized,
    payButtonDisabled,
    cardholderName,
    cancelButton,
    loadingShimmer,
    setLoadingShimmer,
    errorMsgCard,
    cardClick,
    setCardClick,
    gpayClick,
    setGpayClick,
  } = useCardsCheckout({ ...data });

  const { gPayApi, gpayLoader, errorMsg } = useGpay(data);

  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { lang: langSlug } = useParams();

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "checkoutltd",
            gatewayMerchantId: REACT_APP_CHECKOUT_PUBLIC_KEY,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: REACT_APP_GPAY_MERCHANT_ID,
      merchantName: REACT_APP_GPAY_MERCHANT_NAME,
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPrice: data?.amount,
      currencyCode: "OMR",
      countryCode: "OM",
    },
  };

  return (
    <div className={Style.payment_detail}>
      <div className={`h5 ${Style.payment_detail_title}`}>{t("ENTER_PAYMENT_DETAILS")}</div>

      <div className={Style.field_group}>
        {/* <div className={`${Style.field_wrapper} ${Style.credit_card}`}>
          <Form.Check
            type="radio"
            name="type"
            id="card"
            // defaultChecked={true}
            label={t("cardType")}
            onClick={() => {
              setCardClick(!cardClick);
              setGpayClick(false);
            }}
          />
          <CreditCard />
        </div> */}

        {/* {cardClick && ( */}
        <div className={`row ${Style.custom_row}`}>
          <Frames
            config={{
              debug: false,
              publicKey: REACT_APP_CHECKOUT_PUBLIC_KEY,
              localization: langSlug == "ar" ? "AR" : "EN-GB",
              style: {
                base: {
                  direction: langSlug == "ar" ? "rtl" : "ltr",
                },
              },
              cardholder: {
                name: cardholderName,
              },
            }}
            cardValidationChanged={onCardValidationChanged}
            cardTokenized={handleOnCardTokenized}
            name={cardholderName}
          >
            <div className="col-12">
              <label className={Style.form_group}>
                <span>{t("Card_Number")} *</span>
                <CardNumber className={Style.form_control} />
              </label>
            </div>
            <div className={`col-12 ${Style.card_name}`}>
              <span>{t("Card_Holder_Name")} *</span>

              <Form.Control
                type="text"
                id="cardholder-name"
                className={Style.form_control}
                placeholder={t("Card_Holder_Name")}
                onChange={handleCardholderName}
              />
            </div>
            <div className="col-12">
              <label className={Style.form_group}>
                <span>{t("Expiry_date")} *</span>
                <ExpiryDate className={Style.form_control} />
              </label>
            </div>
            <div className="col-12">
              <label className={Style.form_group}>
                <span>{t("CVV")} *</span>
                <Cvv className={Style.form_control} />
              </label>
            </div>

            <div className="col-5">
              <button
                // variant="tertiary"
                type="submit"
                className={`${Style.payment_btn} custom_btn custom_btn-tertiary`}
                onClick={cancelButton}
              >
                {t("CANCEL")}
              </button>
            </div>

            <div className="col-5">
              <button
                // variant="primary"
                type="submit"
                className={
                  loadingShimmer
                    ? ` ${Style.payment_btn} loading custom_btn custom_btn-primary`
                    : `${Style.payment_btn} custom_btn custom_btn-primary`
                }
                disabled={payButtonDisabled}
                id="payButton"
                onClick={() => {
                  Frames.submitCard();
                  setLoadingShimmer(true);
                }}
              >
                {t("PAY")}
              </button>
            </div>
          </Frames>
        </div>
        {/* )} */}
        {/* <div className={Style.field_wrapper}>
          <Form.Check
            type="radio"
            name="type"
            id="set_date"
            label={t("Gpay")}
            onClick={() => {
              setGpayClick(!gpayClick);
              setCardClick(false);
            }}
          />
          <img src={GpaySvg} alt="Gpay" />
        </div>
        {gpayClick ? (
          gpayLoader ? (
            <div className="col-5">
              <Button
                variant="primary"
                type="submit"
                className={` ${Style.payment_btn} loading`}
                id="payButton"
              >
                {t("PAY")}
              </Button>
            </div>
          ) : (
            <GooglePayButton
              environment="TEST"
              buttonType="short"
              paymentRequest={paymentRequest}
              onLoadPaymentData={(res) => gPayApi(res, data?.id)}
              buttonLocale={langSlug == "ar" ? "ar" : "en"}
            />
          )
        ) : (
          ""
        )} */}
        {errorMsgCard ? <div>{errorMsgCard}</div> : null}

        {errorMsg ? <div>{errorMsg}</div> : null}
      </div>

      <div className="col-12">
        <div className={Style.amount_summary}>
          {t("Total_amount")} :
          <span>
            {" "}
            <sup>{t("omr")}</sup>
            {parseInt(data.amount)}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPayment;
