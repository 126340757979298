import axios from "axios";
import { useState, useEffect } from "react";

let footerMenu = {};
const useFooter = () => {
  const [footer, setFooter] = useState({ footer: null, script: null });

  useEffect(() => {
    const lang = localStorage.getItem("language_type");

    axios
      .get(
        `https://cloudlab.ikea.ae/afgikeapps/corsproxy/ownav.php?lang=${
          !lang ? "en" : lang
        }&country=om&fragment=footer-lazy`
      )
      .then((resp) => {
        footerMenu.footer = resp?.data;
        setFooter({ ...footerMenu });
      });

    axios
      .get(
        `https://cloudlab.ikea.ae/afgikeapps/corsproxy/ownav.php?lang=${
          !lang ? "en" : lang
        }&country=om&fragment=script`
      )
      .then((resp) => {
        footerMenu.script = resp?.data;
        setFooter({ ...footerMenu });
      });
  }, []);
  return footer;
};

export default useFooter;
