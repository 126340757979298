import React, { useEffect } from "react";
import Style from "./Checkout.module.scss";
import CheckoutFor from "./CheckoutFor";
import OrderSummary from "./OrderSummary";
import CheckoutPayment from "./CheckoutPayment";
import { useStorePurchase } from "../../logic/useStorePurchase";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VatAlert from "./VatAlert";

const Checkout = () => {
  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const { slug, lang: langSlug } = useParams();

  const { fetchCardDetails, details } = useStorePurchase();

  useEffect(() => {
    fetchCardDetails(slug);
  }, []);

  const lang = localStorage.getItem("language_type");
  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  return (
    <section className={Style.checkout_page}>
      <div className="container">
        <div className="sm_container">
          <div className="text-start">
            <h1 className="h5">{t("CHECKOUT")}</h1>
          </div>
          <div className={`row ${Style.custom_row}`}>
            <div className="col-md-6">
              <CheckoutFor data={details} />
            </div>
            <div className="col-md-6">
              <OrderSummary data={details} />
              <VatAlert />
              {details?.id && <CheckoutPayment data={details} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Checkout;
