import Style from "./Transaction.module.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStorePurchase } from "../../logic/useStorePurchase";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getCardStatus } from "../../store/slices/cardApisSlice";

const TransactionSuccess = () => {
  const { orderId, langTrans: langSlug } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (window?.location?.search) {
      // let code = window?.location?.search.slice(1).split("=")[1];
      dispatch(
        getCardStatus({
          status: "success",
          order_id: orderId,
          token: localStorage.getItem("authToken"),
        })
      );
    }
  }, []);

  const { t } = useTranslation(["common"]);

  const { fetchCardDetails, details } = useStorePurchase();

  useEffect(() => {
    fetchCardDetails(orderId);
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("switcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const handleHide = () => {
    const lang = localStorage.getItem("language_type");
    if (lang === "ar") {
      navigate("/ar");
      window.location.reload();
    } else {
      navigate("/en");
      window.location.reload();
    }
  };

  useEffect(() => {
    if (window.location.pathname.split("/")[3] == "success") {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        window.history.go(1);
      };
    }
  }, [window.location.pathname]);

  return (
    <div className={"container"}>
      <>
        <div className={Style.transaction_wrapper}>
          <h2 className={Style.transaction_heading}> {t("order_summary")}</h2>
          <div className={Style.transaction_content}>
            <h2 className={Style.transaction_content_title}> {t("Order_placed_successfully")}</h2>
            <div className={Style.order_details}>
              <div>
                <p>
                  {" "}
                  {t("Your_order_number")} # <strong>{details?.id}</strong>
                </p>
                <p>
                  {t("Order_total")}:
                  <strong>
                    <sup>{t("omr")}</sup> {parseInt(details?.amount)}
                  </strong>
                </p>
              </div>
              <div>
                <div className={Style.card_status_items}>
                  {t("Purpose_of_buying")} :
                  <span>{details?.purchase_mode == 1 ? t("Bought") : t("Gifted")}</span>
                </div>
              </div>
            </div>

            <div class={Style.fig_wrapper}>
              <figure class={Style.fig_img_wrapper}>
                <img src={details?.gift_card?.gift_card_full_url} alt="Transaction" />
              </figure>
            </div>
            <div className={Style.card_status}>
              <div className={Style.card_status_block}>
                <div className={Style.card_status_items}>
                  {t(!details?.sender_name ? "Sender_Name" : "Recipient_Name2")}
                  :&nbsp;
                  <span>{details?.name}</span>
                </div>
                <div className={Style.card_status_items}>
                  {t(!details?.sender_name ? "Sender_Email" : "Recipient_mail2")}
                  :&nbsp;
                  <span>{details?.email}</span>
                </div>
                <div className={Style.card_status_items}>
                  {t(!details?.sender_name ? "Sender_Mobile_Number_Sender" : "Recipient_Number2")}
                  :&nbsp;<span>{details?.mobile}</span>
                </div>
              </div>
              <div className={Style.card_status_block}>
                {details?.sender_name && (
                  <div className={Style.card_status_items}>
                    {t("Senders_Name_check")}:&nbsp;
                    <span>{details?.sender_name}</span>
                  </div>
                )}
                <div className={Style.card_status_items}>
                  {t("Arrive_ASAP")}:&nbsp;
                  <span>
                    {new Date().toDateString() === new Date(details?.date).toDateString()
                      ? t("Now")
                      : moment(details?.date, "yyyy/MM/DD").format("DD/MM/yyyy")}
                  </span>
                </div>
              </div>
            </div>

            <div className={Style.btn_wrapper}>
              <Link
                className={`custom_btn custom_btn-primary ${Style.home_button}`}
                to="/"
                onClick={handleHide}
              >
                {t("Home")}
              </Link>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TransactionSuccess;
