import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCardCheckoutApi } from "../store/slices/cardApisSlice";

let data;

export const useGpay = (params) => {
  const REACT_APP_CHECKOUT_PUBLIC_KEY =
    process.env.REACT_APP_CHECKOUT_PUBLIC_KEY;

  const [gpayLoader, setGpayLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { t } = useTranslation(["common"]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { lang: langSlug } = useParams();

  let langString = langSlug;

  useEffect(() => {
    if (!langSlug) {
      const lang = localStorage.getItem("language_type");
      if (lang === "ar") {
        navigate("/ar");
      } else if (lang === "en") {
        navigate("/en");
      } else {
        navigate("/en");
      }
    }
  }, []);

  const gPayApi = (res, id) => {
    setGpayLoader(true);
    data = res?.paymentMethodData?.tokenizationData?.token;
    axios
      .post(
        "https://api.checkout.com/tokens",
        {
          type: "googlepay",
          token_data: {
            protocolVersion: "ECv1",
            signature: JSON.parse(data)?.signature,
            signedMessage: JSON.parse(data)?.signedMessage,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: REACT_APP_CHECKOUT_PUBLIC_KEY,
          },
        }
      )
      .then(function (response) {
        let tokenId = response?.data?.token;
        const payload = {
          token: tokenId,
          order_id: id,
          app_token: localStorage.getItem("authToken"),
          card_type: response?.data?.card_type,
          issuer_country: response?.data?.issuer_country,
          scheme: response?.data?.scheme,
          card_no: response?.data?.last4,
        };

        dispatch(getCardCheckoutApi(payload))
          .unwrap()
          .then((res) => {
            setGpayLoader(false);

            let responseWith3D = res?.data?.data?.status;
            let responseWithOut3D = res?.data?.data?.approved;

            if (responseWith3D == "Pending") {
              window.location.href = res?.data?.data?._links?.redirect?.href;
            } else if (responseWithOut3D == true) {
              navigate(`/order-summary/${id}/success/${langString}`);
            } else if (responseWithOut3D == false) {
              navigate(`/order-summary/${id}/failed/${langString}`);
            } else {
              setErrorMsg(t("Something_wrong"));
              setTimeout(() => setErrorMsg(""), 4000);
            }
          });
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  return {
    gPayApi,
    gpayLoader,
    setGpayLoader,
    errorMsg,
  };
};
